import React, { useState } from 'react';
import {
  Col, Row,
} from 'antd';
import './styles.scss';
import {
  ExclamationCircleOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { MARK_AS_READ, REVIEW_FEEDBACK } from '../../../graphql/mutations/HistoricalData';
import CustomModal from '../../CustomModal';
// eslint-disable-next-line import/named
import { GET_ISSUES_BY_ID } from '../../../graphql/queries/HIstoricalData';
import Comment from './Comment';
import showNotification from '../../Notification';
import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from '../../../settings/constants';

const Notification = function ({ item, refetchNotifications } : any) : JSX.Element {
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const { data: issueData, loading } = useQuery(GET_ISSUES_BY_ID, {
    variables: { issues: [item.notification.issueFeedback.issue.id] },
  });
  const [markAsRead] = useMutation(MARK_AS_READ);
  const [reviewFeedback] = useMutation(REVIEW_FEEDBACK);
  const setFeedbackAsRead = (id: number, notificationId: number, isApproved: boolean) => {
    markAsRead({ variables: { userNotificationId: id } })
      .then(() => reviewFeedback({ variables: { feedbackId: notificationId, isApproved } })
        .then((response) => {
          const { data } = response;
          showNotification(data.reviewFeedback.feedback.message, data.reviewFeedback.error
            ? ERROR_NOTIFICATION : SUCCESS_NOTIFICATION);
        }).then(() => {
          refetchNotifications();
        }));
  };
  const setVisibleModal = () => {
    setIsVisibleModal(!isVisibleModal);
  };
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        tabIndex={item.id}
        role="link"
        className="ant-notification-notice notification notification-warning ant-notification-notice-closable"
        onClick={() => setVisibleModal()}
      >
        <div className="ant-notification-notice-content">
          <div className="ant-notification-notice-with-icon" role="alert">
            <span className="ant-notification-notice-icon"><ExclamationCircleOutlined /></span>
            <div className="ant-notification-notice-message">New issue feedback</div>
            <div className="ant-notification-notice-description">{item.notification && item.notification.message}</div>
          </div>
        </div>
      </div>
      { !loading ? (
        <CustomModal
          title={issueData.getIssuesByIds[0].name}
          type={issueData.getIssuesByIds[0].type}
          className="feedback-modal"
          isVisible={isVisibleModal}
          setIsVisible={setIsVisibleModal}
              /* eslint-disable-next-line @typescript-eslint/no-empty-function */
          action={() => {}}
          multipleRows
          closable
          hasFooter={false}
        >
          <div className="issue-description">
            <p className="description">{issueData.getIssuesByIds[0].description}</p>
            <Row>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <h3>Dekoding Estimated</h3>
                <div>
                  {issueData.getIssuesByIds[0].timeRequired
                    ? parseFloat(issueData.getIssuesByIds[0].timeRequired).toFixed(2)
                    : '-'}
                  {' '}
                  h.
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <h3>Average Estimated</h3>
                <div>
                  {issueData.getIssuesByIds[0].averageEstimatedTime
                    ? parseFloat(issueData.getIssuesByIds[0].averageEstimatedTime).toFixed(2)
                    : '-'}
                  {' '}
                  h.
                </div>
              </Col>
            </Row>
          </div>
          <div className="issue-comments">
            <h3 className="font-x-large">Comments waiting to be approved</h3>
            <div className="comment-list">
              <Comment
                profilePicture=""
                comment={item.notification.issueFeedback.comment}
                estimatedTime={item.notification.issueFeedback.estimatedTime}
              />
              <div className="actions">
                <CheckCircleFilled
                  className="check-feedback"
                  onClick={() => setFeedbackAsRead(item.id, parseInt(item.notification.id, 10), true)}
                />
                {' '}
                <CloseCircleFilled
                  className="close-feedback"
                  onClick={() => setFeedbackAsRead(item.id, parseInt(item.notification.id, 10), false)}
                />
              </div>
            </div>
          </div>
        </CustomModal>
      ) : ''}
    </>
  );
};

export default Notification;
