import React from 'react';
import './styles.scss';
import { Row } from 'antd';
import { textCopyright } from '../../views/Login/constants';

const CopyrightText = function () {
  return (
    <Row justify="center" className="copyright-container">
      <span className="montserrat-regular font-small text-copyright">
        { textCopyright }
      </span>
      <span className="montserrat-regular font-small vertical-bar">|</span>
      <span className="montserrat-regular font-small text-links">
        Imprint
      </span>
      <span className="montserrat-regular font-small vertical-bar">|</span>
      <span className="montserrat-regular font-small text-links">
        Data Protection
      </span>
    </Row>
  );
};

export default CopyrightText;
