import React, { useEffect, useState } from 'react';
import {
  getAuthTokenName,
  getExpirationTokenName,
  getRefreshTokenName,
  getRememberMeName,
  isNull,
  logout,
} from '../../utils/tools';
import LayoutView from '../Layout';
import { MAX_INACTIVITY_TIME, WARNING_NOTIFICATION } from '../../settings/constants';
import showNotification from '../../components/Notification';

type typeBaseRoutes = {
  [key: string]: JSX.Element | any
}

const baseStates : typeBaseRoutes = {
  loading: null,
  component: (props : any) => <LayoutView {...props} />,
};

let time: NodeJS.Timeout;

const Index = function (): JSX.Element | null {
  const [base] = useState('component');
  const accessToken = localStorage.getItem(getAuthTokenName());
  const expireRefreshTokenTimeStamp = localStorage.getItem(getRefreshTokenName());
  const expireTokenTimeStamp = localStorage.getItem(getExpirationTokenName());
  const rememberMe = localStorage.getItem(getRememberMeName());
  const [login, setLoginState] = useState(!isNull(accessToken) ? accessToken : '');
  const [groups, setGroups] = useState();

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (login) {
      const timeInSeconds = new Date().getTime() / 1000;
      if (rememberMe) {
        if (expireRefreshTokenTimeStamp) {
          if (timeInSeconds >= parseFloat(expireRefreshTokenTimeStamp)) {
            showNotification('Session expired', WARNING_NOTIFICATION);
            logout(setLoginState);
          } else {
            setTimeout(() => {
              showNotification('Session expired', WARNING_NOTIFICATION);
              logout(setLoginState);
            }, (parseFloat(expireRefreshTokenTimeStamp) - timeInSeconds) * 1000);
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (expireTokenTimeStamp) {
          if (timeInSeconds >= parseFloat(expireTokenTimeStamp)) {
            logout(setLoginState);
          } else {
            setTimeout(() => {
              showNotification('Session expired', WARNING_NOTIFICATION);
              logout(setLoginState);
            }, (parseFloat(expireTokenTimeStamp) - timeInSeconds) * 1000);
          }
        }
      }
      const resetTimer = () => {
        clearTimeout(time);
        time = setTimeout(() => {
          showNotification('Session expired', WARNING_NOTIFICATION);
          logout(setLoginState);
        }, MAX_INACTIVITY_TIME);
      };
      resetTimer();
      document.onmousemove = resetTimer;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      document.onmousemove = () => {};
      clearTimeout(time);
    }
  }, [expireRefreshTokenTimeStamp, expireTokenTimeStamp, login, rememberMe]);

  const componentProps = {
    login,
    setLoginState,
    groups,
    setGroups,
  };

  return (baseStates[base](componentProps));
};

export default Index;
