import { gql } from 'apollo-boost';

/**
 * Mutation to log in
 * @param email
 * @param password
 */
export const LOGIN_VERIFICATION = gql`
  mutation ($email: String!, $password: String!){
      tokenAuth(email: $email, password: $password) {
        payload
        refreshExpiresIn
        token
      }
     }
`;

/**
 * Mutation to refresh token
 * @param $token
 */
export const REFRESH_TOKEN = gql`
    mutation RefreshToken($token: String!){
      refreshToken(token: $token){
        payload
        refreshExpiresIn
        token
      }
    }
`;

export const CHANGE_PASSWORD = gql`
mutation ChangePassword($input: ChangePasswordInput!){
  changePassword(input: $input){
    feedback{
      message
      status
    }
    errors{
      field
      messages
    }
  }
}
`;

export const EDIT_USER = gql`
mutation EditUser($input: EditUserInput!){
  editUser(input: $input){
    feedback{
      message
      status
    }
    errors{
      field
      messages
    }
  }
}
`;
