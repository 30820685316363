import React, {
  createContext, Dispatch, useEffect, useReducer,
} from 'react';
import { useQuery } from '@apollo/client';
import {
  getAuthTokenName,
  logout,
} from '../utils/tools';
import GET_USER from '../graphql/queries/User';
import siderMenus from '../components/Sidebar/siderMenus';
import { routesPermission, defaultModulesSuperuser, defaultModulesDeveloper } from '../routes/contants';

type InitialStateType = {
  me: {
    userInformation: {
      id: string;
      firstName: string,
      lastName: string,
      projects: Array<any>,
      userPermissions: Array<any>,
      profilePicture: string,
      isStaff: any,
      isSuperuser: any,
      startTime: string,
      hours: number,
      groups: Array<any>,
    }
  };
};
type dispatchType = {
  type: string,
  data: InitialStateType,
}

const initialState = {
  me: {
    userInformation: {
      id: '',
      firstName: '',
      lastName: '',
      projects: [],
      userPermissions: [],
      isStaff: null,
      isSuperuser: null,
      profilePicture: '',
      startTime: '',
      hours: 0,
      groups: [],
    },
  },
};

export const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<dispatchType>;
}>({
  state: initialState,
  dispatch: () => null,
});

const AppContextProvider = function (props: { children: any;
  setLoginState: any; setGroups: any }) : JSX.Element {
  const { children, setLoginState, setGroups } = props;

  const authToken = localStorage.getItem(getAuthTokenName());

  const {
    data, loading, refetch, error,
  } = useQuery(GET_USER, {
    fetchPolicy: 'no-cache',
    skip: !authToken,
  });

  const reducer = (state: any, action: { type: any; data: any; }) => {
    switch (action.type) {
      case 'default':
      case 'clearInfo':
        return initialState;
      case 'updateInfo':
        return { me: action.data.me };
      case 'refetch':
        refetch();
        return state;
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (error) {
      logout(setLoginState);
    } else if (data && authToken) {
      const allItems = siderMenus;
      data.getUser.groups = data.getUser.isSuperuser ? defaultModulesSuperuser : defaultModulesDeveloper;
      const groupsArray = data.getUser.groups.map((elm: { title: string; }) => elm.title);
      const redirections : string[] = [];
      allItems.forEach((item) => groupsArray?.includes(item.title)
            && redirections.push(item.redirection));
      setGroups(redirections);
      dispatch({
        type: 'updateInfo',
        data: { me: { userInformation: data.getUser } },
      });
    }
  }, [data, error, authToken, setLoginState]);

  return (
  // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
