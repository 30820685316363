const routesDictionary = {

  vacation: {
    route: '/vacation',
    moduleName: 'Vacation',
  },
  publicView: '/publicProject/:uid',
  publicViewFunc: (id) => `/publicProject/${id}`,
  developerDetail: '/developers/:id',
  developerDetailFunc: (id) => `/developers/${id}`,
  projectDetail: {
    route: '/projects/:id',
    moduleName: 'projectDetails',
  },
  projectDetailFunc: (id) => `/projects/${id}`,
  // TODO: DEPRECATED, when activated this the reCaptcha, unlogged pages
  //  will render a white row below-down the page. To fully add this functionality
  //  remove comment related to historical Data con project.
  /*
   historicalData: {
    route: '/historical-data/!*',
    queryRoute: '/historical-data',
    moduleName: 'historicalData',
  }, */
  projectQuality: {
    route: '/project-quality',
    moduleName: 'projectQuality',
  },
  myProjectDetail: {
    route: '/my-projects/:id',
    moduleName: 'myProjects',
  },
  myProjectDetailFunc: (id) => `/my-projects/${id}`,
  myProjects: {
    route: '/my-projects',
    moduleName: 'myProjects',
  },
  developers: {
    route: '/developers',
    moduleName: 'developers',
  },
  projects: {
    route: '/projects',
    moduleName: 'projects',
  },
  login: {
    route: '/login/',
    moduleName: 'login',
  },
  noPermission: '/no-permission',
  tokenExpired: '/token-expired',
};

export default routesDictionary;
