import React from 'react';

export default function asyncComponent(
  importComponent: () => PromiseLike<{ default: any; }> | { default: any;
    },
) {
  class AsyncComponent extends React.Component<any, any> {
    constructor(props?: any) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component,
      });
    }

    render() {
      const DownloadComponent:any = this.state.component;

      return DownloadComponent ? <DownloadComponent {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}
