const imageContainer = {
  xs: { span: 24 },
  md: { span: 13 },
};

export const copyrightContainer = {
  xs: { span: 24 },
  md: { span: 12 },
};
export default imageContainer;
