import routesDictionary from './routesDict';
import asyncComponent from '../HOC/AsyncComponent';

const AsyncLogin = asyncComponent(() => import('../views/Login'));
const AsyncDevelopers = asyncComponent(() => import('../views/Developers'));
const AsyncProjects = asyncComponent(() => import('../views/Projects'));
const AsyncProjectDetail = asyncComponent(() => import('../views/ProjectDetail'));
const AsyncMyProjects = asyncComponent(() => import('../views/MyProjects'));
const AsyncDeveloperDetail = asyncComponent(() => import('../views/DeveloperDetail'));
const AsyncPublicView = asyncComponent(() => import('../views/PublicView'));
const AsyncNoPermission = asyncComponent(() => import('../views/NoAccess/NoPermission'));
const AsyncTokenExpired = asyncComponent(() => import('../views/NoAccess/TokenExpired'));
const AsyncMyProjectDetail = asyncComponent(() => import('../views/ProjectDetail'));
const AsyncHistoricalData = asyncComponent(() => import('../views/HistoricalData'));
const AsyncVacation = asyncComponent(() => import('../views/NoAccess/FeatureNotReady'));

const routes = [
  {
    path: routesDictionary.vacation.route,
    component: AsyncVacation,
    type: 'private',
    title: 'Vacation',
  },
  /* {
    path: routesDictionary.historicalData.route,
    component: AsyncHistoricalData,
    type: 'private',
    title: 'Historical Data',
  }, */
  {
    path: routesDictionary.myProjectDetail.route,
    component: AsyncMyProjectDetail,
    type: 'private',
    title: 'My Project Detail',
  },
  {
    path: routesDictionary.noPermission,
    component: AsyncNoPermission,
    type: 'private',
    title: 'No Permission',
  },
  {
    path: routesDictionary.tokenExpired,
    component: AsyncTokenExpired,
    type: 'persistent',
    title: 'View Expired',
  },
  {
    path: routesDictionary.publicView,
    component: AsyncPublicView,
    type: 'persistent',
    title: 'Public View',
  },
  {
    path: routesDictionary.developerDetail,
    component: AsyncDeveloperDetail,
    type: 'private',
    title: 'Developer Detail',
  },
  {
    path: routesDictionary.myProjects.route,
    component: AsyncMyProjects,
    type: 'private',
    title: 'My Projects',
  },
  {
    path: routesDictionary.projectDetail.route,
    component: AsyncProjectDetail,
    type: 'private',
    title: 'Project Detail',
  },
  {
    path: routesDictionary.projects.route,
    component: AsyncProjects,
    type: 'private',
    title: 'Projects',
    moduleName: 'projects',
  },
  {
    path: routesDictionary.developers.route,
    component: AsyncDevelopers,
    type: 'private',
    title: 'Developers',
    moduleName: 'developers',
  },
  {
    path: routesDictionary.login.route,
    component: AsyncLogin,
    type: 'public',
    title: 'Login',
    moduleName: 'login',
  },
];

export default routes;
