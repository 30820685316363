import React from 'react';
import { notification } from 'antd';
import { ReactComponent as InfoCircle } from '../../media/icons/info_circle.svg';
import './styles.scss';

const showNotification = (title: string, type: string, message?: string, duration?: number) => {
  notification.open({
    placement: 'top',
    message: title,
    description: message || '',
    icon: <InfoCircle />,
    className: `notification notification-${type}`,
    style: {
      ...(!message && { paddingTop: 20, paddingBottom: 20 }),
    },
    duration: duration || 4.5,
  });
};

export default showNotification;
