const projectModule = {
  id: 1,
  title: 'Projects',
  subtitle: 'Project Detail',
};

const developersModule = {
  id: 2,
  title: 'Developers',
  subtitle: 'Developer Detail',
};

const myProjectsModule = {
  id: 3,
  title: 'My Projects',
  subtitle: 'My Project Detail',
};

const projectQualityModule = {
  id: 4,
  title: 'Project Quality',
  subtitle: 'Project Quality',
};

/* const historicalDataModule = {
  id: 5,
  title: 'Historical Data',
  subtitle: 'Historical Data',
}; */

const vacationModule = {
  id: 6,
  title: 'Vacation',
  subtitle: 'Vacation Detail',
};

export const routesPermission = [
  projectModule,
  developersModule,
  myProjectsModule,
  projectQualityModule,
  // historicalDataModule,
];

export const defaultModulesSuperuser = [
  projectModule,
  developersModule,
  // historicalDataModule,
  vacationModule,
];

export const defaultModulesDeveloper = [
  vacationModule,
];
