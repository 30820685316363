import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar, Badge, Col, Divider, Dropdown, Empty, Layout, Menu, Row,
} from 'antd';
import {
  LogoutOutlined, MenuOutlined, UserOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

import routesDictionary from '../../routes/routesDict';
import { ReactComponent as Logo } from '../../media/images/logo.svg';
import { ReactComponent as BellIcon } from '../../media/icons/bell.svg';
import { ReactComponent as ArrowDownIcon } from '../../media/icons/arrow_down.svg';
import { ReactComponent as ArrowUpIcon } from '../../media/icons/arrow_up.svg';
import Sidebar from '../Sidebar';
import UserInfo from '../UserInfo';
import { logout } from '../../utils/tools';
import { AppContext } from '../../contexts';
import { BACKEND_URL } from '../../settings/constants';
import { GET_NOTIFICATIONS } from '../../graphql/queries/User';
import { NOTIFICATION_SUBSCRIPTION } from '../../graphql/subscriptions/HistoricalData';

import Notification from './Notification';
import './styles.scss';

const { Content } = Layout;

// eslint-disable-next-line complexity
const Header = function ({
  children, setLogin, userInformation, login,
} : any) : JSX.Element {
  const [collapsedResponsive, setCollapsedResponsive] = useState(true);
  const [collapsedMenu, setCollapsedMenu] = useState(false);
  const [collapsedNotificationsMenu, setCollapsedNotificationsMenu] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(true);
  const navigate = useNavigate();

  const { state } = useContext(AppContext);
  const { me } = state;

  const {
    data: dataSubscription, loading: loadingSubscription,
  } = useSubscription(NOTIFICATION_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
  });
  const {
    data: dataNotifications, refetch: refetchNotifications,
  } = useQuery(GET_NOTIFICATIONS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (dataSubscription) {
      refetchNotifications();
    }
  }, [dataSubscription, refetchNotifications]);

  const headerMenu = (
    <Row align="middle" onClick={() => setCollapsedMenu(!collapsedMenu)}>
      <Row className="avatar-icon">
        <Avatar
          size="large"
          {...(me?.userInformation?.profilePicture
            ? { src: `${BACKEND_URL}${me?.userInformation?.profilePicture}` }
            : { icon: <UserOutlined /> })}
        />
      </Row>
      <Row>
        <span className="user-name">
          {`${me.userInformation.firstName || ''} ${me.userInformation.lastName || ''}`}
        </span>
      </Row>
      <Row>
        {React.createElement(collapsedMenu ? ArrowUpIcon : ArrowDownIcon, {
          className: 'arrow-icon',
        })}
      </Row>
    </Row>
  );
  const menu = (
    <Menu className="menu-container">
      <Menu.Item
        key="setting"
        className="sub-menu"
        icon={<UserOutlined />}
        onClick={() => navigate(
          routesDictionary.developerDetailFunc(me.userInformation.id),
          { replace: true },
        )}
      >
        <span className="dropdown-header">
          Profile
        </span>
      </Menu.Item>
      <Divider />
      <Menu.Item
        key="logout"
        className="sub-menu"
        icon={<LogoutOutlined />}
        onClick={() => logout(setLogin)}
      >
        <span className="dropdown-header">
          Logout
        </span>
      </Menu.Item>
    </Menu>
  );
  const notificationsMenu = (notificationsData: any[]) => {
    const notifications = notificationsData.filter((item) => item.status === 'NEW');
    return (
      <Menu className="menu-container notifications">
        { notifications.length > 0 ? notifications.map((item: any) => (
          <Menu.Item
            key={item.id}
            className="sub-menu"
          >
            <Notification
              item={item}
              refetchNotifications={refetchNotifications}
              setVisibleNotifications={setVisibleNotifications}
            />
          </Menu.Item>
        )) : <Empty className="empty-notifications" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </Menu>
    );
  };
  return (
    <div className="vh-100">
      {login
          && (
          <Row className="w-100 header">
            <Col className="menu-icon">
              <MenuOutlined onClick={() => setCollapsedResponsive(!collapsedResponsive)} />
            </Col>
            <Col className="logo-container">
              <Logo className="logo" />
            </Col>
            <Col className="header-user-information">
              <Row className="notification">
                {
              dataNotifications ? (
                <Dropdown
                  overlayClassName={!visibleNotifications ? 'ant-dropdown-hidden' : ''}
                  overlay={notificationsMenu(dataNotifications.getNotifications.notifications)}
                  placement="bottomRight"
                  arrow
                  trigger={['click']}
                >
                  <Badge count={dataNotifications.getNotifications.unread} offset={[5, -6]}>
                    <BellIcon
                      className={dataNotifications.getNotifications.unread ? 'cursor-pointer' : ''}
                    />
                  </Badge>
                </Dropdown>
              ) : ''
            }
              </Row>
              <Row className="user-info-container" align="middle">
                <Dropdown
                  overlay={menu}
                  placement="bottomRight"
                  visible={collapsedMenu}
                  arrow
                  trigger={['click']}
                >
                  {headerMenu}
                </Dropdown>
              </Row>
            </Col>
          </Row>
          )}
      <Layout>
        {login
          && (
          <Sidebar collapsedResponsive={collapsedResponsive} userInformation={me.userInformation} />
          )}
        <Layout>
          <Content className="children-container">
            {login
                && (
                <UserInfo userInformation={me.userInformation} />
                )}
            <div className="main-content">
              {children}
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default Header;
