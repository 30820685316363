import { gql } from 'apollo-boost';

const GET_USER = gql`
query{
    getUser{
        id
        firstName
        lastName
        country{
            name
        }
        timeHired
        projects{
          id
          title
        }
        groups{
          id
          name
        }
        userPermissions{
          id
          name
        }
        isStaff
        isSuperuser
        profilePicture
        startTime
        hours
    }
}
`;

export const GET_USER_OPTIONS = gql`
query{
  getUserOptions{
    roles{
      id
      name
    }
    titles{
      id
      name
    }
  }
}
`;

export const GET_USER_BY_ID = gql`
query($userId: ID!){
  getUserById(userId: $userId){
    id    
    firstName
    lastName
    email
    country{
      name
    }
    timeHired
    projects{
      id
      title
      description
      startDate
      endDate
      projectPicture
    }
    groups{
      id
      name
    }
    startDate
    birthday
    description
    profilePicture
    linkedin
    twitter
    facebook
    instagram
    curriculum
    qualificationSet{
      id
        role{
          id
          name
        }
      title
    }
    startTime
    hours
    skills {
      name
      description
      category{
        name
      }
      technologyPicture
      url
    }
  }
}
`;

export const GET_NOTIFICATIONS = gql`
query{
  getNotifications{
    unread
    notifications{
      id
      status
      notification{
        id
        message
        issueFeedbackId
        issueFeedback{
        issue{
            id
        }
        comment
        estimatedTime
        }
      }
    }
  }
}
`;

export default GET_USER;
