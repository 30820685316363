import { gql } from 'apollo-boost';

export const GET_USER_STORIES = gql`
query($sessionId: UUID){
  getUserStories(sessionId: $sessionId){
    id
    name
    description
  }
}
`;

export const GET_USER_STORIES_BY_IDS = gql`
query ($userStories: [ID]!) {
  getUserStoriesByIds(userStories: $userStories) {
    id
    name
    description
  }
}
`;

export const GET_TAGS = gql`
query{
  getTags{
    id
    name
  }
}
`;

export const GET_ISSUES = gql`
query($userStoryId: ID!){
  getIssues(userStoryId: $userStoryId){
    id
    name
    description
    timeRequired
    averageEstimatedTime
    tags{
      id
      name
    }
    comments{
      id
      name
      comment
      estimatedTime
    }
  }
}
`;

export const GET_SESSION = gql`
query{
  getSession{
    id
  }
}
`;

export const GET_ALL_ISSUES = (number, after) => gql`
query{
  getAllIssues(first: ${number}, after: "${after}"){
    pageInfo{
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    edges{
      node{
        id
        name
        description
        timeRequired
        averageEstimatedTime
        pendingComments
        tags{
          id
          name
        }
        comments{
          id
          name
          comment
          estimatedTime
        }
      }
      cursor
    }
  }
}
`;

export const GET_ISSUES_BY_ID = gql`
query ($issues: [ID]!) {
  getIssuesByIds(issues: $issues) {
    id
    name
    description
    timeRequired
    tags{
      id
      name
    }
    averageEstimatedTime
    comments{
      id
      name
      comment
    }
  }
}
`;
