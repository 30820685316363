export const AUTH_TOKEN = '@%!str-2v@ir_io!';
export const REFRESH_TOKEN = 'refreshToken';
export const EXP_TOKEN = 'expirationToken';
/** global: AUTH_TOKEN */
export const BACKEND_URL = typeof process.env.REACT_APP_DEV_BACKEND_URL !== 'undefined'
  ? process.env.REACT_APP_DEV_BACKEND_URL : false;
/** global: BACKEND_URL */
export const GRAPHQL_URL = typeof process.env.REACT_APP_DEV_GRAPHQL_URL !== 'undefined'
  ? process.env.REACT_APP_DEV_GRAPHQL_URL : '/graphql/';

export const API_URL = `${BACKEND_URL}${GRAPHQL_URL}`;
/** global: API_URL */
export const WS_URL = typeof process.env.REACT_APP_DEV_WS_URL !== 'undefined'
  ? process.env.REACT_APP_DEV_WS_URL : '';
/** global: WS_URL */

export const ERROR_NOTIFICATION = 'error';
export const SUCCESS_NOTIFICATION = 'success';
export const INFO_NOTIFICATION = 'info';
export const WARNING_NOTIFICATION = 'warning';

export const RESPONSIVE_BREAKPOINTS = ['xs', 'sm', 'md'];

export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const DATE_FORMAT = 'YYYY-MM-DD';

export const ISSUE_ID_LIST = 'issueIdList';
export const MAX_INACTIVITY_TIME = 1800000;
export const REMEMBER_ME = 'keepLogin';
export const LOCALSTORAGE_TAGS_KEY = 'tags';

export const USER_STORY_EXCEL = '/historical_data/user_story_excel/';

export const XLSX_FORMAT = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';

export const RECAPTCHA_V3_KEY = typeof process.env.REACT_APP_DEV_RECAPTCHA_V3_CLIENT_KEY !== 'undefined'
  ? process.env.REACT_APP_DEV_RECAPTCHA_V3_CLIENT_KEY : '';
export const RECAPTCHA_V2_KEY = typeof process.env.REACT_APP_DEV_RECAPTCHA_V2_CLIENT_KEY !== 'undefined'
  ? process.env.REACT_APP_DEV_RECAPTCHA_V2_CLIENT_KEY : '';
