import React, { useState } from 'react';
import {
  Routes, Route, Navigate,
} from 'react-router-dom';

import routes from '../../routes/routeList';
import MakeRouteWithSubRoutes from '../../routes';
import routesDictionary from '../../routes/routesDict';
import AppContextProvider from '../../contexts';
import Header from '../../components/Header';

const LayoutView = function (props : any): JSX.Element {
  const {
    setLoginState, setGroups, groups, login,
  } = props;
  // const navigate = useNavigate();
  // const client = useApolloClient();

  const [userInformation] = useState({
    firstName: '',
    lastName: '',
  });
  return (
    <div>
      <AppContextProvider setLoginState={setLoginState} setGroups={setGroups}>
        <Header
          setLogin={setLoginState}
          userInformation={userInformation}
          login={login}
        >
          <Routes>
            {routes.map((route) => (
              <Route
                path={route.path}
                key={route.path}
                element={(
                  <MakeRouteWithSubRoutes
                    key={route.path}
                    component={route.component}
                    path={route.path}
                    title={route.title}
                    type={route.type}
                    {...props}
                    userInformation={userInformation}
                  />
              )}
              />
            ))}
            <Route
              path="*"
              element={<Navigate replace to={groups ? groups[0] : routesDictionary.myProjects.route} />}
            />
          </Routes>
        </Header>
      </AppContextProvider>
    </div>
  );
};

export default LayoutView;
