import React from 'react';
import {
  Avatar, Col, Row, Typography,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import './styles.scss';
import { BACKEND_URL } from '../../../../settings/constants';

interface Props {
  profilePicture: string
  comment: string
  estimatedTime: string
}

const { Text } = Typography;

const Comment:React.FC<Props> = function ({
  profilePicture,
  comment,
  estimatedTime,
}) {
  const navigate = useNavigate();

  return (
    <div className="comment-container user-info-card-container">
      <Row>
        <Col className="user-info-card-avatar">
          <div
            role="button"
            className="avatar"
            tabIndex={0}
            onKeyDown={() => ''}
          >
            <Avatar
              size={60}
              {...(profilePicture
                ? { src: `${BACKEND_URL}${profilePicture}` }
                : { icon: <UserOutlined /> })}
            />
          </div>
        </Col>
        <Col className="user-info-card-content">
          <Row className="font-large">
            <Text
              className="user-info-card-name"
            >
              Anonymous user (
              {estimatedTime}
              {' h.'}
              )
            </Text>
          </Row>
          <Row>
            <Col span={24}>
              <p className="text montserrat-regular font-regular">
                {comment}
              </p>
            </Col>
          </Row>
        </Col>

      </Row>
    </div>
  );
};

export default Comment;
