import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    InMemoryCache,
    NormalizedCacheObject,
    RequestHandler,
    split,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import jwt_decode, {JwtPayload} from 'jwt-decode';

import {REFRESH_TOKEN} from "../../graphql/mutations/User";
import {getAuthTokenName, setLoginTokens} from "../../utils/tools";
import {API_URL, WS_URL} from "../../settings/constants";
import Base from "../Base";
import {WebSocketLink} from "@apollo/client/link/ws";
import {getMainDefinition} from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";

export function isRefreshNeeded(token?: string | null) {
    if (!token) {
        return {valid: false, needRefresh: true};
    }

    const decoded = jwt_decode<JwtPayload>(token);

    if (!decoded) {
        return {valid: false, needRefresh: true};
    }
    if (decoded.exp && Date.now() >= decoded.exp * 1000) {
        return {valid: false, needRefresh: true};
    }
    return {valid: true, needRefresh: false};
}

export let client: ApolloClient<NormalizedCacheObject>;

const refreshAuthToken = async () => {
    return await client
        .mutate({
            mutation: REFRESH_TOKEN,
            variables: {token: localStorage.getItem(getAuthTokenName())},
        })
        .then(({data}) => {
            const {refreshToken} = data;
            if (refreshToken && refreshToken.token) {
                const {
                    refreshExpiresIn, token,
                } = refreshToken;
                setLoginTokens(token, refreshExpiresIn);
                return token;
            }
        });
};

const apolloHttpLink = createUploadLink({
    uri: API_URL || '',
    fetch: global.fetch,
});

const apolloAuthLink = setContext(async (request, {headers}) => {
    if (request.operationName !== 'RefreshToken') {

        let token = localStorage.getItem(getAuthTokenName())
        const shouldRefresh = isRefreshNeeded(token);

        if (token && shouldRefresh.needRefresh) {
            const refreshPromise = await refreshAuthToken();

            if (!shouldRefresh.valid) {
                token = await refreshPromise;
            }
        }

        if (token) {
            return {
                headers: {
                    ...headers,
                    authorization: `JWT ${token}`,
                },
            };
        }
        return {headers};
    }

    return {headers};
});

const splitLink = (apolloWsLink: ApolloLink | RequestHandler) => split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition'
            && definition.operation === 'subscription'
        );
    },
    apolloWsLink,
    (apolloHttpLink as unknown) as ApolloLink,
);

const apolloClient = () => {
    // TODO: Improve wsLink connection
    const apolloWsLink = new WebSocketLink({
        uri: WS_URL,
        options: {
            reconnect: true,
            connectionParams: {
                Authorization: localStorage.getItem(getAuthTokenName())
                    ? `JWT ${localStorage.getItem(getAuthTokenName())}` : '',
            },
        },
    });

    return new ApolloClient({
        link: apolloAuthLink.concat(splitLink(apolloWsLink)),
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
            },
        }
    });
};


const Apollo = (props: any): JSX.Element => {
    return (
        <ApolloProvider client={apolloClient()}>
            <Base {...props} />
        </ApolloProvider>
    );
};

export default Apollo;