import React from 'react';
import {
  Col,
  Row,
} from 'antd';
import '../styles.scss';
import { ReactComponent as NoPermissionImage } from '../../../media/images/no_permission_image.svg';
import CopyrightText from '../../../components/CopyrightText';
import { copyrightContainer } from '../constants';

const NoPermission = function () {
  // const [needRefresh, setNeedRefresh] = useState<boolean>();

  return (
    <div className="no-access-container">
      <Row justify="center">
        <Col className="image-container">
          <NoPermissionImage />
        </Col>
        <Col>
          <h1 className="red-title">SORRY!</h1>
          <h2 className="subtitle">YOU DON&apos;T HAVE PERMISSION</h2>
          <p>_TODO: Change this text. Lorem ipsum dolor sit amet, consetetur sadipscing elitruptua.</p>
        </Col>
      </Row>
      <Row>
        <Col {...copyrightContainer} style={{ justifyContent: 'right' }}>
          <CopyrightText />
        </Col>
      </Row>
    </div>
  );
};

export default NoPermission;
