import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Apollo from './wrappers/Apollo';
import 'antd/dist/antd.css';
import './App.css';
import './styles/base.scss';
import './styles/utils.scss';
import './styles/overwrite.scss';

const App = function (): JSX.Element {
  return (
    <BrowserRouter>
      <Apollo />
    </BrowserRouter>
  );
};

export default App;
