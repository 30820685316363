import React, { Dispatch, SetStateAction, useEffect } from 'react';
import {
  Button, Col, Modal, Row,
} from 'antd';
import { ReactComponent as InfoIcon } from '../../media/icons/info_modal.svg';

import './styles.scss';

interface Props {
    title: string
    subtitle?: string | null
    type: 'error' | 'success' | 'info' | 'warning'
    children?: React.ReactNode
    isVisible: boolean
    setIsVisible: Dispatch<SetStateAction<boolean>>
    action: any
    multipleRows: boolean
    leftButtonText?: string
    rightButtonText?: string
    secondaryAction?: any
    disabledRightButton?: boolean
    className?: string
    closable?: boolean
    hasFooter?: boolean
}

const defaultProps: {
    children: React.ReactNode,
    subtitle: string | null,
    leftButtonText: string,
    rightButtonText: string
    secondaryAction: any
    disabledRightButton: boolean
    className: string
    closable: boolean
    hasFooter: boolean
} = {
  children: null,
  subtitle: null,
  leftButtonText: 'CANCEL',
  rightButtonText: 'ACCEPT',
  secondaryAction: null,
  disabledRightButton: false,
  className: '',
  closable: false,
  hasFooter: true,
};
// eslint-disable-next-line complexity
const CustomModal : React.FC<Props> = function (
  {
    title,
    subtitle,
    type,
    children,
    isVisible,
    setIsVisible,
    action,
    multipleRows,
    leftButtonText,
    rightButtonText,
    secondaryAction,
    disabledRightButton,
    className,
    closable,
    hasFooter,
  },
) {
  const handleAccept = () => {
    action();
  };

  const handleCancel = () => {
    if (secondaryAction) secondaryAction();
    else setIsVisible(false);
  };
  const head = (
    <Row>
      {!children && (
      <Col>
        <InfoIcon className="info-icon" />
      </Col>
      )}

      <Col>
        <h3 className="custom-modal-title montserrat-medium font-x-large">{title}</h3>
        {subtitle && (
        <span className="custom-modal-description font-small">
          {subtitle}
        </span>
        ) }
      </Col>
    </Row>
  );

  const footer = (
    <Row gutter={12}>
      <Col span={12}>
        <Button
          className="ant-btn-secondary"
          onClick={handleCancel}
        >
          {leftButtonText}
        </Button>
      </Col>
      <Col span={12}>
        <Button
          type="primary"
          onClick={handleAccept}
          disabled={disabledRightButton}
        >
          {rightButtonText}
        </Button>
      </Col>
    </Row>
  );
  return (
    <Modal
      className={`custom-modal-container ${className} ${type} 
        ${children ? 'custom-modal-with-children' : 'custom-modal-without-children'}
        custom-modal-container-${multipleRows}
      `}
      title={head}
      visible={isVisible}
      closable={closable}
      footer={hasFooter && footer}
      onCancel={handleCancel}
    >
      {(children) && children}
    </Modal>
  );
};

CustomModal.defaultProps = defaultProps;

export default CustomModal;
