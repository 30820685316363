import { gql } from 'apollo-boost';

export const EDIT_USER_STORY = gql`
mutation EditUserStory($input: EditUserStoryInput!){
  editUserStory(input: $input){
    feedback{
      message
      status
    }
    errors{
      field
      messages
    }
  }
}
`;

export const CREATE_USER_STORY = gql`
mutation CreateUserStory($input: CreateUserStoryInput!){
  createUserStory(input: $input){
    userStoryId
    feedback{
      message
      status
    }
    errors{
      field
      messages
    }
  }
}
`;

export const CREATE_ISSUE = gql`
mutation CreateIssue($input: CreateIssueInput!){
  createIssue(input: $input){
    feedback{
      message
      status
    }
    errors{
      field
      messages
    }
  }
}
`;

export const EDIT_ISSUE = gql`
mutation EditIssue($input: EditIssueInput!){
  editIssue(input: $input){
    feedback{
      message
      status
    }
    errors{
      field
      messages
    }
  }
}
`;

export const CREATE_ISSUE_FEEDBACK = gql`
mutation CreateIssueFeedback($input: CreateIssueFeedbackInput!){
  createIssueFeedback(input: $input){
    feedback{
      message
      status
    }
    errors{
      field
      messages
    }
  }
}
`;

export const MARK_AS_READ = gql`
mutation($userNotificationId: ID!){
  markAsRead(userNotificationId: $userNotificationId){
    feedback{
      message
      status
    }
    errors{
      field
      messages
    }
  }
}
`;

export const REVIEW_FEEDBACK = gql`
mutation($feedbackId: ID!, $isApproved: Boolean!){
  reviewFeedback(feedbackId: $feedbackId, isApproved: $isApproved){
    feedback{
      message
      status
    }
    errors{
      field
      messages
    }
  }
}
`;
