import routesDictionary from '../../routes/routesDict';
import { ReactComponent as ProjectsIcon } from '../../media/icons/projects.svg';
import { ReactComponent as DevelopersIcon } from '../../media/icons/developers.svg';
import { ReactComponent as ProjectIcon } from '../../media/icons/my_project.svg';
import { ReactComponent as QualityIcon } from '../../media/icons/quality.svg';
import { ReactComponent as HistoryIcon } from '../../media/icons/history.svg';
import { ReactComponent as VacationIcon } from '../../media/icons/vacation.svg';

const siderMenus = [
  {
    title: 'Projects',
    key: `${routesDictionary.projects.route.split('/')[1]}-menu`,
    redirection: routesDictionary.projects.route,
    name: routesDictionary.myProjects.moduleName,
    icon: ProjectsIcon,
  },
  {
    title: 'Developers',
    key: `${routesDictionary.developers.route.split('/')[1]}-menu`,
    redirection: routesDictionary.developers.route,
    name: routesDictionary.developers.moduleName,
    icon: DevelopersIcon,
  },
  {
    title: 'My Projects',
    key: routesDictionary.myProjects.route.split('/')[1],
    redirection: routesDictionary.myProjects.route,
    name: routesDictionary.myProjects.moduleName,
    icon: ProjectIcon,
  },
  /* {
    title: 'Project Quality',
    key: routesDictionary.projectQuality.route.split('/')[1],
    redirection: routesDictionary.projectQuality.route,
    name: routesDictionary.projectQuality.moduleName,
    icon: QualityIcon,
  }, */
  /* {
    title: 'Historical Data',
    key: routesDictionary.historicalData.route.split('/')[1],
    redirection: routesDictionary.historicalData.queryRoute,
    name: routesDictionary.historicalData.moduleName,
    icon: HistoryIcon,
  }, */
  {
    title: 'Vacation',
    key: routesDictionary.vacation.route.split('/')[1],
    redirection: routesDictionary.vacation.route,
    name: routesDictionary.vacation.moduleName,
    icon: VacationIcon,
  },
];

export default siderMenus;
