import moment from 'moment';
import React from 'react';
import {
  AUTH_TOKEN, DATE_FORMAT, EXP_TOKEN, ISSUE_ID_LIST, LOCALSTORAGE_TAGS_KEY, REFRESH_TOKEN, REMEMBER_ME, XLSX_FORMAT,
} from '../settings/constants';

export function getAuthTokenName() {
  return AUTH_TOKEN;
}

export function isNull(value) {
  return value === null;
}

export function getRefreshTokenName() {
  return REFRESH_TOKEN;
}

export function getRememberMeName() {
  return REMEMBER_ME;
}

export function getExpirationTokenName() {
  return EXP_TOKEN;
}

export const setLoginTokens = (accessToken, refreshToken, expToken, rememberMe) => {
  localStorage.setItem(getAuthTokenName(), accessToken);
  localStorage.setItem(getRefreshTokenName(), refreshToken);
  localStorage.setItem(getExpirationTokenName(), expToken);
  if (rememberMe) {
    localStorage.setItem(getRememberMeName(), rememberMe);
  } else {
    localStorage.removeItem(getRememberMeName());
  }
};

export const logout = (setLogin) => {
  localStorage.removeItem(getAuthTokenName());
  localStorage.removeItem(getRefreshTokenName());
  localStorage.removeItem(getExpirationTokenName());
  setLogin('');
};

export const getIssueIdList = () => JSON.parse(localStorage.getItem(ISSUE_ID_LIST)) || [];

export const addIssueId = (id) => {
  localStorage.setItem(ISSUE_ID_LIST, JSON.stringify([...getIssueIdList(), ...id]));
};

export const dataURItoBlob = async (response) => {
  const blob = await response.blob();
  const newBlob = new Blob([blob], {
    type: XLSX_FORMAT,
  });

  const blobUrl = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute('download', 'Historical Data');
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(blobUrl);
};

export const endTime = (startTime, hours) => {
  const minutes = hours * 60;
  return moment(startTime, 'HH:mm').add(minutes, 'minutes').format('HH:mm');
};

export const isWorkingTime = (workStartTime, workEndTime, currentTime) => {
  const momentStartTime = moment(workStartTime, 'HH:mm');
  const momentEndTime = moment(workEndTime, 'HH:mm');
  const momentCurrentTime = moment(currentTime, 'HH:mm');
  return momentCurrentTime.isBetween(momentStartTime, momentEndTime);
};

export const getBusinessDays = (currentTime) => {
  const dayInMonth = moment().startOf('month');
  const currentDayInMonth = moment(currentTime);
  let businessDays = 0;

  while (dayInMonth.isSameOrBefore(currentDayInMonth, 'day')) {
    if (dayInMonth.day() !== 0 && dayInMonth.day() !== 6) {
      businessDays += 1;
    }
    dayInMonth.add(1, 'd');
  }
  return businessDays;
};

export const openInNewTab = (link) => {
  window.open(link, '_blank', 'noopener,noreferrer');
};

export const isWorkingTimeFinished = (currentTime, endWorkTime) => moment(currentTime, 'HH:mm')
  .isAfter(moment(endWorkTime, 'HH:mm'));

export const isWorkingTimeStarted = (currentTime, startTime) => moment(currentTime, 'HH:mm')
  .isBefore(moment(startTime, 'HH:mm'));

export function developerWorkTimeSort(currentTime, startTime, endWorkTime) {
  let developerSortValue = 3;
  if (isWorkingTimeStarted(currentTime, startTime)) {
    developerSortValue = 2;
  } else if (isWorkingTimeFinished(currentTime, endWorkTime)) {
    developerSortValue = 1;
  } else if (!startTime) {
    developerSortValue = 0;
  }
  return developerSortValue;
}

export const setArrayLocalStorage = (data) => localStorage.setItem(LOCALSTORAGE_TAGS_KEY, JSON.stringify(data));

export const getArrayLocalStorage = (dataTag) => JSON.parse(localStorage.getItem(dataTag)) || [];

export function validateDate(firstDate) {
  return moment(firstDate).format(DATE_FORMAT) || moment().format(DATE_FORMAT);
}
