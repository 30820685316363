import { gql } from 'apollo-boost';

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription{
      feedbackNotification{
        userNotification{
          id
          status
          notification{
            id
            message
            issueFeedbackId
          }
        }
      }
    }
`;

export default NOTIFICATION_SUBSCRIPTION;
