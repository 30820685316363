import React, {
  useEffect, useState,
} from 'react';

import {
  Col, Divider, Row,
} from 'antd';

import moment from 'moment-timezone';
import { TIME_REFRESH_INTERVAL, PERU_TIMEZONE } from '../../utils/constants';

import './styles.scss';

const UserInfo = function ({ userInformation } : any) {
  const [[localTime, localMeridian], setLocalTime] = (
    useState(moment().format('h:mm A').split(' '))
  );
  const [[timezone, zoneMeridian], setTimezone] = (
    useState(moment.tz(PERU_TIMEZONE).format('h:mm A').split(' '))
  );
  const refreshTime = () => {
    setLocalTime(moment().format('h:mm A').split(' '));
    setTimezone(moment.tz(PERU_TIMEZONE).format('h:mm A').split(' '));
  };

  useEffect(() => {
    setInterval(refreshTime, TIME_REFRESH_INTERVAL);
  }, []);

  return (
    <Row className="dashboard-info-container" align="middle">
      <Col xl={10} lg={20} md={20} sm={20} xs={21} className="user-info-container">
        <p className="message montserrat-regular font-medium">
          This is your Dashboard
        </p>
        <span className="user-message montserrat-regular font-xx-large">
          {`Hello ${userInformation.firstName || ''} ${userInformation.lastName || ''}`}
        </span>
      </Col>

      <Col xl={3} lg={6} md={6} sm={6} xs={6} className="datetime-container white-background">
        <div className="datetime-content">
          <span className=" montserrat-medium font-xx-large">
            {moment().format('DD')}
          </span>
          <p className="label montserrat-regular font-medium">
            {moment().format('MMM').toUpperCase()}
          </p>
        </div>
      </Col>

      <Col className="col-container" xl={1} lg={1} md={1} sm={1} xs={1}>
        <div className="divider-container">
          <Divider type="vertical" />
        </div>
      </Col>

      <Col xl={4} lg={8} md={8} sm={8} xs={8} className="datetime-container white-background">
        <div className="datetime-content">
          <span className="main-content montserrat-medium font-xx-large">
            {localTime}
          </span>
          <span className="main-content montserrat-medium font-small">
            {` ${localMeridian}`}
          </span>
          <p className="label montserrat-regular font-medium">
            LOCAL TIME
          </p>
        </div>
      </Col>

      <Col className="col-container" xl={1} lg={1} md={1} sm={1} xs={1}>
        <div className="divider-container">
          <Divider type="vertical" />
        </div>
      </Col>

      <Col xl={4} lg={8} md={8} sm={8} xs={8} className="datetime-container white-background">
        <div className="datetime-content">
          <span className="main-content montserrat-medium font-xx-large">
            {timezone}
          </span>
          <span className="main-content montserrat-medium font-small">
            {` ${zoneMeridian}`}
          </span>
          <p className="label montserrat-regular font-medium">
            PERU TIME
          </p>
        </div>
      </Col>

    </Row>
  );
};

export default UserInfo;
