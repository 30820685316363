import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AppContext } from '../contexts';
import NoPermission from '../views/NoAccess/NoPermission';

import routesDictionary from './routesDict';
import { routesPermission } from './contants';

interface Props {
    /**
     * The route path
     */
    path: string
    /**
     * Component to be rendered
     */
    component: any
    // component: AsyncComponent

    /**
     * Path type private, public, persistent
     */
    type: string,

    /**
     * Tab Title
     */
    title: string,

    login: React.Component

    setLoginState: React.FC

    userInformation: any

    groups: any

    setGroups: any
}

type tRouteOptions = {
    [key: string]: (props : Props) => JSX.Element
}

const routeTypes : tRouteOptions = {
  // eslint-disable-next-line complexity
  private: (props : Props) : JSX.Element => {
    const {
      component: Component, login, setLoginState, userInformation, title,
    } = props;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { state } = useContext(AppContext);
    const { me } = state;
    const { groups } : any = me.userInformation;
    const groupsArray : string [] = [];
    groups?.forEach((elm: { title: string, subtitle: string }) => groupsArray.push(elm.title, elm.subtitle));
    if (!login && (routesPermission.slice(-1)[0].title === title)) {
      return (
        <Component publicView />
      );
    }

    if (!login) {
      return (
        <Navigate to={routesDictionary.login.route} />
      );
    }

    if (!groupsArray?.includes(title)) {
      return (
        <NoPermission />
      );
    }
    return (
      <Component />
    );
  },

  public: (props : Props) : JSX.Element => {
    const {
      component: Component, login, setLoginState, userInformation, groups,
    } = props;
    if (login && groups) {
      return (
        <Navigate to={groups[0]} />
      );
    }
    return (
      <Component setLogin={setLoginState} />
    );
  },

  persistent: (props : Props) : JSX.Element => {
    const {
      component: Component, setLoginState,
    } = props;

    return (
      <Component setLogin={setLoginState} />
    );
  },
};

const MakeRouteWithSubRoutes = function (props : Props): JSX.Element {
  const {
    path, title,
    component: Component, type, login, setLoginState, userInformation, groups, setGroups,
  } = props;
  document.title = title;
  return (
    routeTypes[type]({
      component: Component,
      path,
      type,
      title,
      login,
      setLoginState,
      userInformation,
      groups,
      setGroups,
    })
  );
};

export default MakeRouteWithSubRoutes;
