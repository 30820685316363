import React, { useEffect, useState } from 'react';
import {
  Grid, Layout, Menu,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from '../../media/icons/arrow_left.svg';
import { RESPONSIVE_BREAKPOINTS } from '../../settings/constants';
import './styles.scss';
import siderMenus from './siderMenus';
import routesDictionary from '../../routes/routesDict';

const { Sider } = Layout;
const { useBreakpoint } = Grid;
const allItems = siderMenus;

const Sidebar = function ({ collapsedResponsive, userInformation } : any) {
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = () => {
    setCollapsed(!collapsed);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  const [pathName, setPathName] = useState<string>('');
  const [breakpoint, setBreakpoint] = useState('');

  useEffect(() => {
    Object.entries(screens)
      .filter((screen) => screen[1])
      .map((screen) => setBreakpoint(screen[0]));
  }, [screens]);

  useEffect(() => {
    setPathName(`/${location.pathname.split('/')[1]}`);
  }, [location]);

  const handleSelect = ({ key } : any) => {
    navigate(key, { replace: true });
  };

  const { groups } = userInformation;
  const groupsArray = groups?.map((elm: { title: string; }) => elm.title);
  const items = allItems.filter((item) => groupsArray?.includes(item.title));

  return (
    <Sider
      breakpoint="lg"
      width={276}
      className="sidebar-container"
      collapsed={!RESPONSIVE_BREAKPOINTS.includes(breakpoint) ? collapsed : collapsedResponsive}
      collapsedWidth={RESPONSIVE_BREAKPOINTS.includes(breakpoint) ? '0' : '82'}
      style={RESPONSIVE_BREAKPOINTS.includes(breakpoint) ? { position: 'absolute', height: '100vh', zIndex: 1000 } : {
        left: 0, position: 'relative', overflow: 'auto',
      }}
    >
      <Menu
        theme="dark"
        mode="vertical"
        inlineCollapsed
        selectedKeys={[pathName]}
        onSelect={handleSelect}
      >
        <Menu.Item className="collapsed-icon-container" key="collapse-icon" disabled>
          <ArrowLeftIcon
            className={collapsed ? 'collapsed-icon' : 'expanded-icon'}
            onClick={onCollapse}
          />
        </Menu.Item>
        {
          items.map((menu) => (
            <Menu.Item key={menu.redirection} icon={<menu.icon />}>
              {menu.title}
            </Menu.Item>
          ))
        }
      </Menu>
    </Sider>
  );
};

export default Sidebar;
